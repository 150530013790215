import PropTypes from "prop-types"
import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

const Header = ({ siteTitle }) => {
  const { language, languages, originalPath } = useI18next()
  const languageNames = {
    fr: "Français",
    en: "English",
    nl: "Nederlands",
  }
  return (
    <header id="main-header">
      <ul className="languages">
        {languages.map(lng => (
          <li key={lng} className={language === lng ? "active" : ""}>
            <Link to={originalPath} language={lng}>
              {languageNames[lng]}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
