import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import logo from "../images/logo.svg"
import SchemaOrg from "./SchemaOrg"
import { useI18next } from "gatsby-plugin-react-i18next"
function SEO({ description, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
          }
        }
      }
    `
  )
  const { language } = useI18next()
  const metaTitle = site.siteMetadata.title || title
  const metaDescription = site.siteMetadata.description || description
  const url = site.siteMetadata.url
  return (
    <>
      <Helmet
        htmlAttributes={{
          language,
        }}
        title={metaTitle}
        titleTemplate={`${metaTitle} | ${metaDescription}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `image`,
            content: logo,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: url,
          },
          {
            property: `og:image`,
            content: logo,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            property: `twitter:image`,
            content: logo,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `format-detection`,
            content: "telephone=no", //prevent Mobile Safari from automatically detecting phone numbers
          },
        ]}
      />
      <SchemaOrg image={logo} description={metaDescription} />
    </>
  )
}
SEO.defaultProps = {
  title: "Acces Construction",
  description: "Rénovation en minervois",
}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
