import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
export default React.memo(({ description, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            name
            url
            email
            telephone
          }
        }
      }
    `
  )

  const { url, name, telephone, email } = site.siteMetadata
  const baseSchema = [
    {
      "@context": "http://schema.org",
      // "@type": "WebSite",
      "@type": "LocalBusiness",
      url,
      name,
      alternateName: name,
      description: description,
      image,
      organization: name,
      telephone,
      email,
      canonicalUrl: url,
    },
  ]

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(baseSchema)}</script>
    </Helmet>
  )
})
